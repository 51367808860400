const pathChannel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
	pathFilter = [
		'',
		'create/aberto',
		'create/fechado',
		'create/pesquisa',
		'humano/fila',
		'humano/aberto',
		'humano/atendimento',
		'humano/retorno_cliente',
		'humano/fechado',
		'humano/ligacao_fila',
		'humano/ligacao',
		'humano/pesquisa',
		'grupos',
		'livestream',
		'humano/flow',
		'create/flow',
	],
	path = [
		'chats',
		'flow',
		'flow/novo',
		'campaign',
		'arquivo',
		'configuracoes',
		'crm',
		'workflow',
		'analitico-atendimento',
		'analitico-campanha',
		'analitico-monitoria',
		'analitico-ocorrencia',
		'analitico-pesquisa',
		'analitico-ticket',
		'analitico-transferencia',
		'analitico-watson',
		'dashboard-atendimento',
		'monitoria-auditoria',
		'analitico-logs',
	],
	pathConfig = [
		'departamento',
		'distribuicao',
		'pausas',
		'discador',
		'email',
		'email-marketing',
		'whatsapp',
		'horario',
		'inteligencia',
		'sms',
		'tabulacoes',
		'usuario',
		'faq',
		'blacklist',
		'palavra_bloqueada',
		'widget',
		'widget/novo',
		'tabulacoes/novo',
		'sla',
		'net-promoter-score',
		'video',
		'email-imap',
		'landing-page',
		'instagram',
		'privilegios',
		'mensagem-inatividade',
		'pausar-protocolo',
		'google-chat',
		'role-function',
		'create-ben',
		'slack',
		'widget-inbot',
		'tag',
		'database',
		'open-reason',
		'sendgrid-templates',
		'whatsapp-qr-code',
	]

export { path, pathChannel, pathConfig, pathFilter }
